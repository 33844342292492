<template>
	<div class=".gg-container-1">
		
		
		<div class="search-container">
			<!--输入框-->
			<div class="search-container-fn-input">
				<label>通话类型：</label>
				<el-select
					style="width:200px"
					v-model="searchParams.call_type"
					placeholder="请选择"
					size="mini"
					clearable
					@change="getList('restPage')"
				>
					<template slot="prefix">
						<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
					</template>
					<el-option
						v-for="item in callTypeOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</div>
			
		<!--	<div class="search-container-fn-input">
				<label>客户查询：</label>
				<el-input
					style="width:200px"
					size="mini"
					placeholder="客户姓名"
					prefix-icon="el-icon-search"
					v-model="searchParams.keywords"
					clearable
					@change="getList('restPage')"
				>
				</el-input>
			</div>-->
			
			<div class="search-container-fn-input">
			
			<label>录音：</label>
			<audio style="height: 30px" v-if="audio != '' " controls="controls" autobuffer="autobuffer" autoplay="autoplay">
				<source :src="audio" />
			</audio>
			</div>
			
			<div style="margin-left: auto; display: flex; justify-content: space-around">
				
				<div class="search-container-fn-input"
						 v-if="operateList.indexOf('update_table') > -1"
				>
					<el-button size="mini" type="primary" icon="el-icon-edit-outline"
										 style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleEditTableHead">编辑表头
					</el-button>
				</div>
				
				<div class="search-container-fn-input"
						 v-if="operateList.indexOf('reset_query') > -1"
				>
					<el-button size="mini" type="primary" icon="el-icon-refresh"
										 style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleResetSearch">重置查找
					</el-button>
				</div>
				
			</div>
		
		</div>
		
		<el-table
			border
			size="mini"
			v-loading="listLoading"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			height="625"
			style="width: 100%;z-index:0">
			<el-table-column
				type="index"
				align="center"
				width="50">
			</el-table-column>
			<template
				v-for="(column,index) in tableHead"
			>
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-if="column.field_type === 'textBtn' && column.visible === true "
					show-overflow-tooltip
				>
					<!--<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="hover">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 5px" class="search-icon el-icon-search"></i>
							</div>
							<el-input
									size="mini"
									placeholder=""
									prefix-icon="el-icon-search"
									v-model="searchParams.buy_count"
									clearable
									@change="getList('restPage')"
								>
								</el-input>
						</el-popover>
					</template>-->
					<template slot-scope="scope">
						<a style="font-size: 12px; color: #2379fb"
							 @click.prevent="handleCallDetail(scope.row.id)">
							{{scope.row[column.column_prop]}}
						</a>
					</template>
				</el-table-column>
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.field_type === 'imageBtn' && column.visible === true "
					show-overflow-tooltip
				>
					<template slot-scope="scope">
						<div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
								 @click="handleShowImage(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
							<a>点击查看图片</a>
							<el-image
								style="width: 0px; height: 0px"
								:ref="scope.row[column.column_prop][0]"
								:src="imageUrl"
								:preview-src-list="scope.row[column.column_prop]"
								:z-index="9999">
							</el-image>
						</div>
						<div v-else>
							暂无
						</div>
					</template>
				</el-table-column>
				<el-table-column
					:sortable="tableHeadSortable.includes(column.column_prop)"
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.visible === true"
					show-overflow-tooltip
				>
					<template #header>
						<el-popover placement="bottom" title="" min-width="160" trigger="click" v-if = "searchTableHead.filter( item => item.name == column.column_prop).length > 0" >
							<span slot="reference" class="search-header" >
								<span
									class="search-title"
								>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search"
									 :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
								/>
							</span>
							<el-input
								v-if="'exten' == column.column_prop"
								size="mini"
								placeholder=""
								prefix-icon="el-icon-search"
								v-model="searchParams.exten"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.exten)"
							>
							</el-input>
							
							<el-input
								v-if="'dstnum' == column.column_prop"
								size="mini"
								placeholder=""
								prefix-icon="el-icon-search"
								v-model="searchParams.dstnum"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.dstnum)"
							>
							</el-input>
							<el-input
								v-if="'duration' == column.column_prop"
								size="mini"
								placeholder=""
								prefix-icon="el-icon-search"
								v-model="searchParams.duration"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.duration)"
							>
							</el-input>
							
							<el-input
								v-if="'name' == column.column_prop"
								size="mini"
								placeholder=""
								prefix-icon="el-icon-search"
								v-model="searchParams.keywords"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.keywords)"
							>
							</el-input>
							
							<el-select
								v-else-if="'paging_type_name' == column.column_prop"
								style="width:200px"
								v-model="searchParams.paging_type"
								placeholder="请选择"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.paging_type)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in pagingTypeOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							
							<el-select
								v-else-if="'from' == column.column_prop"
								style="width:200px"
								v-model="searchParams.from_id"
								placeholder="请输入客户来源"
								size="small"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.from_id)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in customerFromOptions"
									:key="item.id"
									:label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
							
							
							<el-select
								v-else-if="'buy_status' == column.column_prop"
								style="width:200px"
								v-model="searchParams.buy_status"
								placeholder="请选择"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.buy_status)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in isBuyOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							
							<el-date-picker
								v-else-if="'start_time' == column.column_prop"
								v-model="choiceDate"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleChangeDate()">
							</el-date-picker>
							
							<el-date-picker
								v-else-if="'end_time' == column.column_prop"
								v-model="choiceDate"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleChangeDate">
							</el-date-picker>
							
							<el-date-picker
								v-else-if="'end_visit_at' == column.column_prop"
								v-model="choiceDateEndVisit"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleEndVisitChangeDate">
							</el-date-picker>
							
							<el-date-picker
								v-else-if="'end_order_at' == column.column_prop"
								v-model="choiceDateEndOrder"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleEndOrderChangeDate">
							</el-date-picker>
							
							<el-date-picker
								v-else-if="'next_visit_at' == column.column_prop"
								v-model="choiceDateNextVisit"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleNextVisitChangeDate">
							</el-date-picker>
							
							<el-select
								v-else-if="'customer' == column.column_prop"
								style="width:200px"
								v-model="searchParams.staff_id"
								placeholder="请选择"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.staff_id)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in staffOptions"
									:key="item.id"
									:label="item.staff_name"
									:value="item.id">
								</el-option>
							</el-select>
						
						</el-popover>
						<span v-else>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
					</template>
					<template slot-scope="scope">
						<span v-if="column.column_prop === 'buy_status'">{{scope.row[column.column_prop] === 1 ? '未购' : '已购' }}</span>
						<span v-else-if="column.column_prop === 'use_status'">
								{{scope.row[column.column_prop] | useStatusFormat }}
							</span>
						<span v-else-if="column.column_prop === 'is_wx'">
								{{scope.row[column.column_prop] == '0' ? '否' : '是'}}
							</span>
						<span v-else-if="column.column_prop === 'supervise_status'">
								{{scope.row[column.column_prop] | formatSuperviseStatus}}
							</span>
						<span v-else>{{scope.row[column.column_prop]}}</span>
					</template>
				</el-table-column>
			</template>
		</el-table>
		<!----------------分页---------------->
		<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
								@pagination="getList"/>
		
		<!--编辑表头-->
		<editTableHead :isActiveEditTableHead.sync="isActiveEditTableHead"
														@_getAdminFieldIndex="_getAdminFieldIndex"
														v-if="isActiveEditTableHead"
    :table_type="table_type"
    >
		
		</editTableHead>
		
		
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getAdminFieldIndex, getCallRecordList, getDownloadCall, getStaffList} from '@/api/ggkq';
import editTableHead from '@/components/editTableHead';
import {mapGetters, mapState} from 'vuex';
export default {
  name: "callRecords",
  components: {
    Pagination,
    editTableHead
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
	  table_options: state => state.user.table_options,
    }),
  },
  async created () {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if(operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this._getStaffList()
    await this._getAdminFieldIndex()
    this.getList()
  },
  data () {
    return {
      table_type:'callRecords',
      searchTableHead: [
        {
          name: 'paging_type_name',
          isSearch: false
        },
        {
          name: 'start_time',
          isSearch: false
        },
        {
          name: 'end_time',
          isSearch: false
        },
        {
          name: 'customer',
          isSearch: false
        },
        {
          name: 'buy_status',
          isSearch: false
        },
        {
          name: 'exten',
          isSearch: false
        },
        {
          name: 'dstnum',
          isSearch: false
        },
        {
          name: 'duration',
          isSearch: false
        },
        {
          name: 'name',
          isSearch: false
        },
      ],
      tableHeadSortable: ['created_at'],
      isActiveEditTableHead: false,
      customerFromOptions: [],
      tableHead: [],
      choiceDate: [],
      staffOptions: [],
      choiceDateStartEnd: [],
      searchParams: {
        paging_type: '', // 0 全部 1 主叫 2 被叫 寻呼类型
        call_type: '', // 0全部 1 有效 2无效  通话类型
        keywords: '',
        start_time:'',
        end_time: '',
			},
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      pagingTypeOptions: [
        {
          label: "全部",
          value: 0
        },
				{
				  label: "主叫",
					value: 1
				},
        {
          label: "被叫",
          value: 2
        },
			],
      callTypeOptions: [
        {
          label: "全部",
          value: 0
        },
        {
          label: "有效",
          value: 1
        },
        {
          label: "无效",
          value: 2
        },
      ],
      total: 0,
      listLoading: false,
      options: [],
      tableData: [],
			audio: "",
      isBuyOptions: [
        {
          label: "已购",
          value: 2
        },
        {
          label: "未购",
          value: 1
        },
      ],
      operateList: [],
    }
  },
  methods: {
    handleSearch(prop, value) {
      this.searchTableHead.forEach(item => {
        if (item.name == prop) {
          if (value == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    handleResetSearch() {
      this.searchParams = {
        type: 'today_to_visit',
        is_wx: '',
        visit_level: '',
        buy_count: '',
      }
      this.searchTableHead.forEach(item => {
        item.isSearch = false
      })
      this.choiceDateStartEnd = []
      this.getList('restPage')
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true
    },
    async _getAdminFieldIndex () {
    //   try {
    //     this.listLoading = true
    //     var params = {
    //       type: 'callRecords'
    //     }
    //     const res = await getAdminFieldIndex(params)
        this.tableHead = this.table_options.callRecords
    //     console.log(this.tableHead)
    //   } catch (err) {
    //     //在此处理错误
    //   } finally {
    //     this.listLoading = false
    //   }

    },
    async _getStaffList () {
      var params = {
        agent_id: this.agentId
      }
      var result = await getStaffList(params)
      console.log(result)
      this.staffOptions = result.data
    },
		
    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      this.searchParams.page = this.listQuery.page
      this.searchParams.limit = this.listQuery.limit
      getCallRecordList(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = _data.per_page
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    handleRefresh() {
      this.choiceDate = []
        this.searchParams = {
        paging_type: '', // 1 主叫 2 被叫 寻呼类型
          call_type: '', // 1 有效 2 无效  通话类型
          keywords: '',
          start_time:'',
          end_time: '',
      }
			
		},
    handleChangeDate() {
      this.searchParams.start_at = []
      this.searchParams.end_at = []
      this.searchParams.start_time =""
      this.searchParams.created_at = ""
      this.searchParams.end_time= ""
      console.log(this.choiceDate)
      var _tmp0
      var _tmp1
      if (this.choiceDate) {
        _tmp0 = this.choiceDate[0].substring(0,20)
        _tmp1 = this.choiceDate[1].substring(0,20)
        this.searchParams.start_time = _tmp0
        this.searchParams.end_time = _tmp1
      } else {
        this.searchParams.created_at = ""
      }
      this.searchTableHead.forEach(item => {
        if (item.name == 'end_time' || item.name == 'start_time') {
          if (this.searchParams.end_time == '' || this.searchParams.start_time == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    async handleCallDetail(id) {
      this.audio = ""
    	var result = await getDownloadCall(id)
			console.log(result)
      this.audio = result.data
		},

		
  }
}
</script>

<style lang="scss" scoped>
	.isSearch{
		color: #F56C6C;
	}
	.search-icon {
		color: #F56C6C;
	}
	
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
